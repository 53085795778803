/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import {
  getKeyWord, getDevice,
} from '../../utils/util';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import * as styles from './recruitment.module.scss';
// import Swipe from '../../components/common/swipe';
import ChooseList from '../../components/chooseList';
import FSTableList from '../../components/FSTableList';
import MobileDropdownChooseList from '../../components/mobile-dropdown-chooselist';
import { changeIsLoadingAllData, saveJobFilters } from '../../state/app';
import ImageBanner from '../../components/ImageBanner';

import { getFSApi } from '../../utils/feishu';
import {
  fetchFSJobCategory, fetchFSJobCitiesByWebsite,
} from '../../utils/api';

import { list2tree, throttle } from '../../utils/helper';

class Recruitment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // banner: [],
      // 移动端的职业筛选
      showChooseList: false,
      // 保存 api 获取完的职业类型 => 平铺的 list
      jobCategory: [],
      isEnWebsite: this.props.location.pathname.indexOf('/en') > -1 ? true : false,
      faqData: [],
      faqShowMore: false,
    };
  }

  // eslint-disable-next-line consistent-return
  fetchCategory = (param = { page_token: '' }, doneFetchCbk = () => { }) =>
    getFSApi(fetchFSJobCategory, param)
      .then((res) => {
        const { page_token, items, has_more } = res;
        this.setState({ jobCategory: this.state.jobCategory.concat(items) });
        if (has_more) {
          this.fetchCategory({ page_token }, doneFetchCbk);
        } else {
          doneFetchCbk();
        }
      });

  fetchPosition = (param) => getFSApi(fetchFSJobCitiesByWebsite, param)

  initData = (data, lang) => {
    data.forEach((item) => {
      if (!item.description && lang === 'zh') {
        item.description = item.name.zh_cn;
      } else {
        item.description = item.name.en_us;
      }
      if (!item.id) {
        item.id = item.city_code;
      }
    });
    return data;
  }

  initJobFilters = (_jobFilters, validJobFilters, props) => {
    const { websiteId, copyright, lang } = props;
    const jobFilters = validJobFilters
      .filter((job) => _jobFilters.includes(job.key));
    jobFilters.map((jobFilter) => {
      jobFilter.data = [{
        id: `all_${jobFilter.description}`, // 全部 = 无筛选条件 = 没有 id
        parent_id: '0', // 飞书的 root 标准
        description: copyright.filter_all_description,
        children: [],
      }];
      if (jobFilter.key === 'category') {
        this.fetchCategory({}, () => {
          const data = this.initData(this.state.jobCategory, lang);
          const jobCategory = list2tree(data,
            copyright.filter_all_children_prefix,
            copyright.filter_all_children_suffix);
          jobFilter.data = jobFilter.data.concat(jobCategory);
          // TODO 优化 Done 目前获取所有数据完成的标志是 category 获取完
          this.props.saveJobFilters(jobFilters);
          // console.log('jobCategory', this.state.jobCategory);
          // console.log('jobFilter', jobFilter);
          this.setState({
            renderTableList: true,
          });
        });
      }
      if (jobFilter.key === 'position') {
        this.fetchPosition({ websiteId })
          .then((res) => {
            const data = this.initData(res, lang);
            jobFilter.data = jobFilter.data.concat(data);
            this.props.saveJobFilters(jobFilters);
          });
      }
    });
    this.props.saveJobFilters(jobFilters);
  }

  componentDidMount() {
    const { data, pageContext } = this.props;
    const copywriting = data.contentfulCopywriting;
    // 误删配置默认展示中文招聘信息
    const websiteId = copywriting.config.websites
      ?.find((website) => website.key === 'recruitment')?.id || '6962795203808168199';
    const { validJobFilters } = copywriting.common;
    const { job_filters } = copywriting.recruitment;

    const providedJobFilters = job_filters;
    // TODO 优化参数
    this.initJobFilters(providedJobFilters, validJobFilters,
      { websiteId, copyright: copywriting.common, lang: pageContext.language });
    this.setState({
      websiteId,
      // 默认的筛选条件为第一个
      activeFilter: job_filters[0],
    });
    // banner.map((image) => { image.img = image[getDevice()]; });
    // this.setState({ banner });
    // 切换设备
    // window.addEventListener('resize', throttle(() => {
    //   banner.map((image) => { image.img = image[getDevice()]; });
    //   this.setState({ banner });
    // }, 500));
    const searchKeywords = getKeyWord(this.props, 'wd');
    this.setState({ searchKeywords });
    const myFaqData = [];
    if (copywriting.faq) {
      copywriting.faq.forEach((item) => {
        if (item.type === 'Social Recruitment') {
          myFaqData.push(item);
        }
      });
    }
    this.setState({ faqData: myFaqData });
  }

  getFilterJobList() {
    const { allChooseContent } = this.props;
    const { category, position } = allChooseContent;
    this.props.changeIsLoadingAllData(false);

    this.setState({
      jobad_jobcategory: category,
      jobad_workingplace: position,
      showChooseList: false,
    });
  }

  popUp(type) {
    this.setState({
      showChooseList: true,
    });
    this.setState({
      activeFilter: type,
    });
  }

  hiddenPopup() {
    this.setState({
      showChooseList: false,
    });
  }

  render() {
    const { data } = this.props;
    const copywriting = data.contentfulCopywriting;
    const { seo } = copywriting.recruitment;

    const {
      showChooseList, jobad_jobcategory, jobad_workingplace, searchKeywords, isEnWebsite, faqData, faqShowMore,
    } = this.state;
    const { jobFilters, pageContext, location } = this.props;
    const lang = pageContext.language;

    const changeShowStatus = (index, questionIndex) => {
      const newFaqData = JSON.parse(JSON.stringify(this.state.faqData));
      newFaqData[index].list[questionIndex].isShow = !newFaqData[index].list[questionIndex].isShow;
      this.setState({ faqData: newFaqData });
    };

    return (
      <div className={styles.socialRecruitmentPage}>
        {/* <Swipe items={banner} mod="home" /> */}
        <ImageBanner content={data.contentfulCopywriting.recruitment.newbanner} />
        <Layout {...this.props} copywriting={copywriting}>
          {seo && <SEO title={seo.title} keywords={seo.keywords} description={seo.description} />}
          <div className={`${styles.Recruitment} md:py-[80px] py-10`}>
            <div className={styles.contentBox}>
              <div className={styles.chooseLists}>
                {jobFilters && jobFilters.map((filter) => <ChooseList
                  key={filter.key}
                  title={filter.activeDescription || filter.description}
                  jobFilter={filter}
                  type={filter.key}
                  popUp={this.popUp.bind(this)}
                  getFilterJobList={this.getFilterJobList.bind(this)} />)
                }
              </div>
              <div className={styles.placeholder}></div>
              {
                this.state.renderTableList
                  ? <FSTableList
                    tableHeader={copywriting.common.job_info_header}
                    type="social"
                    lang={lang}
                    copyright={{ ...copywriting.common, ...copywriting.jobTable }}
                    websiteId={this.state.websiteId}
                    jobad_jobcategory={jobad_jobcategory}
                    jobad_workingplace={jobad_workingplace}
                    jobad_keywords={searchKeywords} />
                  : <div className="loading">
                    <img className="loadingContent" src="https://media.number-7.cn/ebike-h5/static/images/common/loading.gif" alt="" />
                  </div>
              }
            </div>
          </div>
          <div className='pb-8 md:pb-[104px]'>
            {faqData && faqData.map((item, index) => (
              <div key={index} className="w-full px-0">
                <div className={`${isEnWebsite ? 'font-[700]' : 'font-[600]'} text-black text-[24px] md:text-[26px] lg:text-[28px] xl:text-[30px] 2xl:text-[32px] leading-[1.5] md:pb-[40px] pb-6`}>{isEnWebsite ? 'Frequently Asked Questions' : '查看常见问题'}</div>
                {item.list && item.list.map((questionItem, questionIndex) => (
                  <React.Fragment key={questionIndex}>
                    {questionIndex < 6 && <div className='last:border-b-0 border-b border-[#CECECF]'>
                      <div className={`my-6 flex justify-between items-start`} onClick={() => {
                        changeShowStatus(index, questionIndex);
                      }}>
                        <div className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#1D1D1F] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] leading-[1.4]`}>{questionItem.question}</div>
                        <div className="iconfont iconarrow_down px-2 py-0 md:py-1 text-[#86868C]" style={{ fontWeight: '700', transform: questionItem.isShow ? 'rotateX(180deg)' : 'rotateX(0deg)', transition: 'ease-in-out 400ms' }} />
                      </div>
                      <div className={`${questionItem.isShow ? 'pb-6' : 'pb-0'} font-[300] text-[#86868C] text-[14px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px] leading-[1.4] overflow-hidden`} style={{ height: questionItem.isShow === true ? 'auto' : '0px', transitionDuration: '400ms' }} dangerouslySetInnerHTML={{ __html: questionItem.answer }} />
                    </div>}
                    {questionIndex >= 6 && faqShowMore && <div className='last:border-b-0 border-b border-[#CECECF]'>
                      <div className={`my-6 flex justify-between items-start`} onClick={() => {
                        changeShowStatus(index, questionIndex);
                      }}>
                        <div className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#1D1D1F] text-[16px] md:text-[20px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] leading-[1.4]`}>{questionItem.question}</div>
                        <div className="iconfont iconarrow_down p-2 py-0 md:py-1 text-[#86868C]" style={{ fontWeight: '700', transform: questionItem.isShow ? 'rotateX(180deg)' : 'rotateX(0deg)', transition: 'ease-in-out 400ms' }} />
                      </div>
                      <div className={`${questionItem.isShow ? 'pb-6' : 'pb-0'} font-[300] text-[#86868C] text-[14px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px] leading-[1.4] overflow-hidden`} style={{ height: questionItem.isShow === true ? 'auto' : '0px', transitionDuration: '400ms' }} dangerouslySetInnerHTML={{ __html: questionItem.answer }} />
                    </div>}
                  </React.Fragment>
                ))}
              </div>
            ))}
            {faqShowMore === false && <div className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#1D1D1F] text-[18px] leading-[1.2] flex justify-center items-center mt-12 cursor-pointer`} onClick={() => this.setState({ faqShowMore: true })}><u>{isEnWebsite ? 'Learn More' : '查看更多'}</u></div>}
          </div>
        </Layout>
        {showChooseList && <MobileDropdownChooseList
          copywriting={copywriting.search}
          location={location}
          activeFilter={this.state.activeFilter}
          hiddenPopup={this.hiddenPopup.bind(this)}
          getFilterJobList={this.getFilterJobList.bind(this)}
        >
        </MobileDropdownChooseList>}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    jobFilters: state.app.jobFilters,
    allChooseContent: state.app.allChooseContent,
    isLoadingAllData: state.app.isLoadingAllData,
  }),
  (dispatch) => ({
    saveJobFilters: (data) => dispatch(saveJobFilters(data)),
    changeIsLoadingAllData: (open) => dispatch(changeIsLoadingAllData(open)),
  }),
)(Recruitment);

export const query = graphql`
query recruitmentQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    faq {
      type
      title
      list {
        question
        answer
      }
    }
    footer {
      brands {
        link
        image
      }
      followus {
        title
        list {
          type
          image
          imageact
          clickFunction
          link
        }
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    config {
      websites {
        key
        id
        description
      }
    }
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
      multiple_description
      validJobFilters {
        description
        key
      }
      filter_all_description
      filter_all_children_prefix
      filter_all_children_suffix
      job_info_header{
        id
        text
        isSort
        default
      }
    }
    recruitment {
      newbanner {
        pc
        mobile
        title
        mobiletitle
        desc
      }
      seo {
        description
        keywords
        title
      }
      job_filters
    }
    jobTable {
      last_page
      next_page
      empty_placeholder
    }
  }
}
`;
